import { LOCALE_RO } from '$i18n/i18n-constants';
import { asArray, isStringNotEmpty } from '@rigu/js-toolkit';
import slugify from 'slugify';
import type { AddressModel, ContactModel, LocationModel } from './contact.models';
import { SubjectType } from './enum.models';
import { ServiceModel, type Supply, type SupplyModel, type SupplyOptions } from './service.models';
import type { WeekDayCode, WorkDayCode, WorkingDaysModel } from './week.models';

export interface NameAliasModel {
    name?: string;
    middle?: string;
    surname?: string;
    wholeName?: string;

    fullName?: string;
}

export class NameAlias {
    name: string;
    middle: string;
    surname: string;
    wholeName: string;

    constructor(
        name: string = '',
        middle: string = '',
        surname: string = '',
        wholeName: string = '',
    ) {
        this.name = name;
        this.middle = middle;
        this.surname = surname;
        this.wholeName = wholeName || this.fullName;
    }

    get fullName(): string {
        return [this.name, this.middle, this.surname].filter(isStringNotEmpty).join(' ');
    }
}

export interface ExecutiveModel {
    nameAlias?: NameAliasModel;
    contact?: ContactModel;
}

export class ExecutiveEntity implements ExecutiveModel {
    nameAlias: NameAliasModel;
    contact: ContactModel;

    constructor(nameAlias: NameAliasModel, contact: ContactModel) {
        this.nameAlias = nameAlias;
        this.contact = contact;
    }
}

export class SkillModel {
    domain: string;

    constructor(domain: string) {
        this.domain = domain;
    }
}

export interface EntityModel {
    uuid: string;
    uri: string;

    serviceTypes: string[];
    serviceGroup: string;

    nameAlias?: NameAliasModel;
    subject: SubjectType;

    address?: AddressModel;
    contact?: ContactModel;

    skills: SkillModel[];
    executive?: ExecutiveModel; // todo: to review DB
    logo: string;

    supply?: SupplyModel;
    supplyOptions?: SupplyOptions;
    languages: string[];

    ranking: number;

    // getters
    allLanguages: string[];
    allServices: ServiceModel[];
    allSkillsDomain: string[];
    allTargetLocations: LocationModel[];
    allTowns: string[];
    allWorkDays: WorkDayCode[];

    mainService: ServiceModel | undefined;
    town: string;

    acceptOvertime: boolean;
    hasDelivery: boolean;
    hasHomeService: boolean;
    isAccredited: boolean;
    isLicenced: boolean;

    hasWeekDay: (weekDayCode: WeekDayCode) => boolean;
    hasWorkDay: (workDayCode: WorkDayCode) => boolean;
}

export class Entity implements EntityModel {
    uuid: string;
    uri: string;

    serviceTypes: string[];
    serviceGroup: string;

    nameAlias?: NameAliasModel;
    subject: SubjectType;

    address?: AddressModel;
    contact?: ContactModel;

    skills: SkillModel[];
    executive?: ExecutiveModel;
    logo: string;

    supply?: Supply;
    supplyOptions?: SupplyOptions;
    languages: string[];

    ranking: number;

    constructor(
        uuid: string,
        serviceGroup: string,
        serviceTypes: string[],
        nameAlias: NameAliasModel | undefined,
        subject: SubjectType,
        contact: ContactModel | undefined,
        address: AddressModel | undefined,
        skills: SkillModel[],
        executive: ExecutiveModel | undefined,
        supply: SupplyModel | undefined,
        supplyOptions: SupplyOptions | undefined,
        languages: string[],
        logo: string,
        ranking: number,
    ) {
        this.uuid = uuid;
        this.serviceTypes = asArray(serviceTypes);
        this.serviceGroup = serviceGroup;

        this.nameAlias = nameAlias;
        this.subject = subject ?? SubjectType.P;

        if (!isStringNotEmpty(this.uuid)) {
            try {
                this.uuid = slugify(this.name, {lower: true, locale: LOCALE_RO});
            }
            catch (e) {
                console.error('ERROR SLUGIFY for serviceGroup', this.serviceGroup);
                console.error('ERROR SLUGIFY for serviceTypes', this.serviceTypes);
                console.error('ERROR SLUGIFY for nameAlias', this.nameAlias);
                console.error('ERROR SLUGIFY for name', this.name);
                // eslint-disable-next-line prefer-rest-params
                console.error('ERROR SLUGIFY for arguments', arguments);
                console.error('ERROR SLUGIFY', e);
                throw 'ERROR SLUGIFY';
            }
        }

        this.uri = '/' + this.uuid;

        this.contact = contact;
        this.address = address;

        this.skills = asArray(skills);
        this.executive = executive;
        this.logo = logo;

        this.supply = supply as Supply;
        this.supplyOptions = supplyOptions;

        this.languages = asArray(languages);

        this.ranking = ranking;
    }

    get name(): string {
        return this.nameAlias?.wholeName ?? '';
    }

    get hasContactData(): boolean {
        return this.contact?.hasData === true;
    }

    get allAddress(): AddressModel[] {
        const supplyAddress =
            !!this.supply && this.supply.hasAddressData ? this.supply.address : undefined;
        return [...asArray(this.address), ...asArray(supplyAddress)];
    }

    get allContacts(): ContactModel[] {
        const supplyContact =
            !!this.supply && this.supply.hasContactData ? this.supply.contact : undefined;
        return [...asArray(this.contact), ...asArray(supplyContact)];
    }

    get town(): string {
        const supplyTown = this.supply?.town;
        if (isStringNotEmpty(supplyTown)) {
            return supplyTown ?? '';
        }
        return this.address?.town ?? '';
    }

    get mainService(): ServiceModel | undefined {
        return this.supply?.mainService;
    }

    get allServices(): ServiceModel[] {
        return this.supply?.services ?? [];
    }

    get allLanguages(): string[] {
        const supplyLanguages = this.supply?.languages;
        return [...asArray(supplyLanguages), ...asArray(this.languages)];
    }

    get allSupplyOptions(): SupplyOptions[] {
        const supplyOptions = !!this.supply && !!this.supply.options ? [this.supply.options] : [];
        return [...asArray(this.supplyOptions), ...supplyOptions];
    }

    get allWeekDays(): WeekDayCode[] {
        return this.supply?.allWeekDays ?? [];
    }

    get allWorkDays(): WorkDayCode[] {
        return this.supply?.allWorkDays ?? [];
    }

    get acceptOvertime(): boolean {
        return this.supplyOptions?.overtime === true;
    }

    get isAccredited(): boolean {
        return this.supplyOptions?.accredited === true;
    }

    get isLicenced(): boolean {
        return this.supplyOptions?.licenced === true;
    }

    get hasDelivery(): boolean {
        return this.supplyOptions?.delivery === true;
    }

    get hasHomeService(): boolean {
        return this.supplyOptions?.homeService === true;
    }

    get allSkillsDomain(): string[] {
        return asArray(this.skills)
            .map(skill => (skill as SkillModel).domain)
            .filter(isStringNotEmpty);
    }

    get supplyWorkingDays(): WorkingDaysModel[] {
        return !this.supply ? [] : this.supply.workingDays;
    }

    get departureDays(): WorkingDaysModel[] {
        return !this.supply ? [] : this.supply.departureDays;
    }

    get returnDays(): WorkingDaysModel[] {
        return !this.supply ? [] : this.supply.returnDays;
    }

    get departureLocations(): LocationModel[] {
        return !this.supply ? [] : this.supply.departureLocations;
    }

    get returnLocations(): LocationModel[] {
        return !this.supply ? [] : this.supply.returnLocations;
    }

    get allTowns(): string[] {
        return [
            ...this.allTargetLocations.map((location) => location.town ?? ''),
            ...asArray(this.supplyOptions?.locations),
        ];
    }

    get allTargetLocations(): LocationModel[] {
        return !this.supply ? [] : [...this.supply.departureLocations, ...this.supply.returnLocations];
    }

    hasWeekDay(weekDayCode: WeekDayCode): boolean {
        return this.allWeekDays.findIndex((wDayCode) => wDayCode === weekDayCode) >= 0;
    }

    hasWorkDay(workDayCode: WorkDayCode): boolean {
        return this.allWorkDays.findIndex((wDayCode) => wDayCode === workDayCode) >= 0;
    }
}
